<template>
  <div>
    <div v-if="backups">
      <b-flix-p>
        <b-flix-h1
          >{{ backups.length }}
          {{ $tc('message.backup', backups.length) }}</b-flix-h1
        >
      </b-flix-p>
      <b-flix-p>
        <b-flix-alert variant="info">
          <span v-html="$t('message.backupInfo')"></span>
        </b-flix-alert>
      </b-flix-p>
      <b-flix-p>
        <b-flix-button
          variant="text-icon"
          :callback="
            () => {
              $router.push({ name: 'dashboardLogs' })
            }
          "
        >
          {{
            $t('message.goTo', {
              name: $tc('message.appointmentOverview', 2) + ' (Server-Logs)'
            })
          }}
        </b-flix-button>
      </b-flix-p>
      <b-flix-list variant="number">
        <li v-for="(backup, index) in backups" :key="backup">
          <b-flix-h3> {{ $tc('message.backup', 1) }}</b-flix-h3>
          <div class="flix-flex flex flex-gap-5">
            <bookingflix-calendar-items
              :settings="
                JSON.stringify(
                  Object.assign(getBackupDate(backup), { view: 'timer' })
                )
              "
            ></bookingflix-calendar-items>
            |
            <bookingflix-calendar-items
              :settings="JSON.stringify(getBackupDate(backup))"
            ></bookingflix-calendar-items>
          </div>
          <b-flix-button
            variant="contained-blue"
            :callback="() => $emit('save', backup)"
            >{{
              $t('message.restore', {
                name: $tc('message.backup', 1) + ' #' + (index + 1)
              })
            }}</b-flix-button
          >
        </li>
      </b-flix-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'backupList',
  components: {},
  props: {},
  data() {
    return {
      backups: false
    }
  },
  computed: {},
  mounted() {
    this.getBackups()
  },
  methods: {
    getBackupDate(backup) {
      let date = backup.split('--')
      date[1] = date[1].split('-').join(':')
      date = date.join(' ')

      return {
        lang: 'de',
        view: 'date-string',
        date: [date, date]
      }
    },
    async getBackups() {
      const formData = new FormData()
      formData.append('user', '346')

      const backups = await fetch(
        'https://backupserver.bookingflix.com/api/list',
        {
          method: 'POST',
          body: formData
        }
      ).then((ret) => ret.json())

      if (backups[0]) {
        this.backups = backups[1]
      }
    }
  }
}
</script>
<style lang="sass" scoped></style>
